import { Icon } from '@iconify/react';
import receipt from '@iconify/icons-ic/receipt';
import layers from '@iconify/icons-ic/layers';
import alert from '@iconify/icons-mdi/alert-decagram';
import envelopeOpen from '@iconify/icons-mdi/email-check';
import errorIcon from '@iconify/icons-mdi/error';
import accountGroup from '@iconify/icons-mdi/account-group';
// routes
import { PATH_DASHBOARD } from '../../routes/paths';
// components
// import Label from '../../components/Label';
import SvgIconStyle from '../../components/SvgIconStyle';

// ----------------------------------------------------------------------

const getIcon = (name) => (
  <SvgIconStyle src={`/static/icons/navbar/${name}.svg`} sx={{ width: '100%', height: '100%' }} />
);

const ICONS = {
  blog: getIcon('ic_blog'),
  cart: getIcon('ic_cart'),
  chat: getIcon('ic_chat'),
  mail: getIcon('ic_mail'),
  user: getIcon('ic_user'),
  kanban: getIcon('ic_kanban'),
  banking: getIcon('ic_banking'),
  calendar: getIcon('ic_calendar'),
  ecommerce: getIcon('ic_ecommerce'),
  analytics: getIcon('ic_analytics'),
  dashboard: getIcon('ic_dashboard'),
  booking: getIcon('ic_booking'),
  receipt: <Icon icon={receipt} width={20} height={20} />,
  layers: <Icon icon={layers} width={20} height={20} />,
  alert: <Icon icon={alert} width={20} height={20} />,
  selectiveProcesses: getIcon('selective_Process'),
  receivedEmail: <Icon icon={envelopeOpen} width={20} height={20} />,
  errorMail: <Icon icon={errorIcon} width={20} height={20} />,
  partner: <Icon icon={accountGroup} width={20} height={20} />
};

const sidebarConfig = [
  {
    subheader: 'gerenciamento',
    items: [
      { title: 'Início', path: PATH_DASHBOARD.root, icon: ICONS.analytics },
      { title: 'Empresas', path: PATH_DASHBOARD.companies.root, icon: ICONS.banking },
      {
        title: 'Usuários',
        path: PATH_DASHBOARD.users.root,
        icon: ICONS.user,
        children: [
          { title: 'Administradores', path: PATH_DASHBOARD.users.superAdmins },
          { title: 'Candidatos', path: PATH_DASHBOARD.users.candidate }
        ]
      },
      { title: 'Processos Seletivos', path: PATH_DASHBOARD.selectiveProcesses.root, icon: ICONS.selectiveProcesses },
      { title: 'Mensagens', path: PATH_DASHBOARD.sendMessage.root, icon: ICONS.mail },
      { title: 'Assinantes', path: PATH_DASHBOARD.subscriptions.root, icon: ICONS.receipt },
      { title: 'Planos', path: PATH_DASHBOARD.plans.root, icon: ICONS.layers },
      {
        title: 'Parceiros',
        path: PATH_DASHBOARD.partners.root,
        icon: ICONS.partner
      },
      {
        title: 'Logs',
        icon: ICONS.receivedEmail,
        children: [
          { title: 'Log de e-mails disparados', path: PATH_DASHBOARD.logsEmails.root },
          { title: 'Log de erros de e-mails', path: PATH_DASHBOARD.logsErrosEmails.root },
          { title: 'Log de mensagens no Whatsapp', path: PATH_DASHBOARD.logsWhatsapp.root },
          { title: 'Log de vagas na catho', path: PATH_DASHBOARD.logsCatho.root },
          { title: 'Log de ações do kanban', path: PATH_DASHBOARD.logging.root },
          { title: 'Erros de envio de e-mails', path: PATH_DASHBOARD.emailErrorLogs.root }
        ]
      }
    ]
  }
];

export default sidebarConfig;
